.home-greeting,
.primary-footer {
  /* allows to use z-index */
  position: relative;
  /* place greeting section on top of snowball background */
  z-index: 200;
}

/* keeps header at the top of the page when scrolling */
.primary-header {
  position: sticky;
  top: 0;
  z-index: 200;
  box-shadow: 0 7px 20px 2px rgba(0, 0, 0, 0.5);
}

.home main {
  position: relative;
  background: rgb(13, 38, 66);
  color: white;
  padding: 3em 0;
}

/* ensures top of sections appears when accessed through header anchor links */
.home main section {
  scroll-margin-top: 4.5em;
  max-width: 100%;
}

/* used to hide sections by default */
.hidden {
  opacity: 0;
}

/* applies to animated backgrounds */
.home #tsparticles {
  height: 100%;
}

/* applies to snowball animtated background */
.home main .background {
  position: fixed;
}

.home main h2 {
  font-size: 2.8em;
  font-weight: 700;
  margin-bottom: 1em;
}

.home .content {
  flex-direction: column;
  max-width: 1024px;
  margin: 0 auto;
}

.home p {
  font-size: 1.2em;
}

/* set name for each skill in skills section */
/* .skills li:first-child {
  grid-area: skill1;
}

.skills li:nth-child(2) {
  grid-area: skill2;
}

.skills li:nth-child(3) {
  grid-area: skill3;
}

.skills li:nth-child(4) {
  grid-area: skill4;
}

.skills li:nth-child(5) {
  grid-area: skill5;
}

.skills li:nth-child(6) {
  grid-area: skill6;
}

.skills li:nth-child(7) {
  grid-area: skill7;
}

.skills li:nth-child(7) {
  grid-area: skill7;
}

.skills li:nth-child(8) {
  grid-area: skill8;
}

.skills li:nth-child(9) {
  grid-area: skill9;
}

.skills li:nth-child(10) {
  grid-area: skill10;
}
.skills li:nth-child(11) {
  grid-area: skill11;
}
.skills li:nth-child(12) {
  grid-area: skill12;
}

.skills li:nth-child(13) {
  grid-area: skill13;
} */
/* */

.skills ul {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 1em;
  /* grid-template-areas: 
    ". . . skill1 skill1 . . ."
    "skill2 skill2 skill3 skill3 skill4 skill4 skill5 skill5"
    "skill6 skill6 skill7 skill7 skill8 skill8 skill9 skill9"
    "skill10 skill10 skill11 skill11 skill12 skill12 skill13 skill13"; */
}

.skills li {
  grid-column: span 2;
}

.skills li:last-child {
  grid-column: 6 / span 2;
}
.skills li:nth-last-child(2) {
  grid-column: 4 / span 2;
}
.skills li:nth-last-child(3) {
  grid-column: 2 / span 2;
}

.skill img[alt="EXPRESS"] {
  filter: invert(100%)
}


/* ensure projects section occupies 100% of container width */
.projects {
  align-self: stretch;
}

.carousel-menu {
  display: none;
}

/* space out projects */
.projects li {
  margin: 5em 0;
}

/* remove bottom margin from last project to avoid excessive spacing between
projects section and contact section */
.projects li:last-child {
  margin-bottom: 0;
}

/* move details to the left to overlap preview for every other project starting
with the first */
.projects li:first-child .details,
.projects li:nth-child(3) .details {
  transform: translateX(-1.2em);
}

/* swap details and preview, and move details to the right to overlap preview 
for every other project starting with the second */
.projects li:nth-child(2) .details,
.projects li:nth-child(4) .details {
  order: -1;
  transform: translateX(2em);
}

/* move details and preview to the right for every other project starting with 
the second */
.projects li:nth-child(2) .project,
.projects li:nth-child(4) .project {
  justify-content: flex-end;
} 

.back-to-top {
  font-size: 3em;
  align-self: flex-end;
  margin-right: 2em;
  transition: transform 0.25s;
}

.back-to-top:hover {
  transform: scale(1.1);
}

/* mobile display */
@media screen and (max-width: 767px) {
  .home main h2 {
    font-size: 2.2em;
  }

  .skills ul {
    display: flex;
    overflow: auto;
    padding: 0 1.5em;
  }

  .home .projects h2 {
    margin-bottom: 1em;
  }

  .projects {
    margin-bottom: 0;
  }
  /*
  .carousel-menu {
    display: flex;
    justify-content: center;
    margin: 3em 0 1em 0;
  }
  
  .carousel-menu a {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid #fff;
    margin: 0 1em;
    background: rgba(255, 255, 255, 0.9);
    scroll-snap-type: none;
  }
  */
  .projects ul {
    display: flex;
    overflow-x: scroll;
    align-items: flex-end;
    scroll-behavior: smooth;
    gap: 2em;
  }

  /* disable horizontal translate on project details */
  .projects li .project .details {
    transform: none;
  }

  .projects li {
    text-align: center;
    margin: 0;
    min-width: 100%;
  }

  .projects .project article.details {
    margin: 0;
  }

  .back-to-top {
    align-self: center;
    margin: 0;
  }
}