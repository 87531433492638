.navbar {
  background: #000;
  display: flex;
  justify-content: flex-end;
  padding: 1em 2em;
  color: #fff;
}

.navbar nav {
  width: 100%;
}

.navbar ul {
  display: flex;
  overflow: auto;
}

.navbar li {
  margin: 0 1em;
}

/* mobile display */
@media screen and (max-width: 767px) {
  .navbar {
    padding: 1em 0.5em;
  }
}
