.greeting {
  position: relative;
  background: rgb(77, 6, 6);
  height: 100vh;
}

.greeting .message {
  font-size: 3.5em;
  font-weight: 600;
  color: #fff;
}

.greeting .message h1 {
  padding: 0.1em 0.2em 0.2em 0.2em;
  margin: 0 auto 0.1em auto;
  width: fit-content;
}

.greeting .message h2 {
  padding: 0.2em;
}

/* applies to name within heading */
.greeting .message span {
  padding: 0 0.1em;
}

.arrow-down {
  margin-top: 3em;
}

.arrow-down span {
  display: block;
  width: 30px;
  height: 30px;
  border-right: 5px solid #fff;
  border-bottom: 5px solid #fff;
  transform: rotate(45deg);
}

.arrow-down .flash:nth-child(2) {
  animation-delay: 0.15s;
}

.arrow-down .flash:nth-child(3) {
  animation-delay: 0.3s;
}

/* mobile display */
@media screen and (max-width: 767px) {
  .greeting .message {
    font-size: 2.5em;
  }

  .greeting .background {
    display: none;
  }

  .arrow-down span {
    width: 25px;
    height: 25px;
  }
}
