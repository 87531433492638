/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
*, *::before, *::after {
	box-sizing: border-box;
	z-index: 10;
}

/* enable smooth scrolling when clicking links to access sections */
html {
	scroll-behavior: smooth;
}

/*
	=========
	Forms
	=========
*/

/* display elements within forms vertically by default */
form {
	display: flex;
	flex-direction: column;
}

/*
	=========
	Fonts
	=========
*/

@import url('https://fonts.googleapis.com/css2?family=Corben:wght@700&family=Georama:wght@400;700&family=Raleway:wght@300;400;500;600;700&display=swap');

body {
	font-family: 'Raleway', sans-serif;
}

.headings-font {
	font-family: 'Corben', cursive;
}

.subheadings-font {
	font-family: 'Georama', sans-serif;
}

/*
	=========
	Inputs and buttons
	=========
*/

input, button, textarea {
	font-family: inherit;
	outline: none;
}

button {
	border: none;
	background: transparent;
	color: inherit;
	font-size: inherit;
}

button:hover {
	cursor: pointer;
}

/*
	=========
	Backgrounds
	=========
*/

/* ensure background is always placed below all other elements */
.background {
	z-index: -100;
}

/* ensure content is always placed on top of background */
.content {
	z-index: 100;
}

/* animated background */
.particles-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
	overflow: hidden;
}

/*
	=========
	Links
	=========
*/

/* by default, remove underline and blue text color from links */
a {
	text-decoration: none;
	color: inherit;
	outline: none;
}

/* class to highlight links */
.active {
  transition: all 0.2s;
  text-shadow: 2px 1px 3px #fff;
  font-weight: 600;
}

/*
	=========
	Headings
	=========
*/

h1, h2 {
	text-align: center;
}

/*
	=========
	Flex layout
	=========
*/

/* template for centered content in flexbox */
.flex-center {
	display: flex;
  justify-content: center;
  align-items: center;
}

/*
	=========
	Container layout
	=========
*/

/* create narrower width */
.narrow {
	width: 50%;
}

/* create spacing above and below */
.space-out {
	margin: 3em 0;
}

/*
	=========
	Text
	=========
*/

p {
	line-height: 1.4;
}

.secondary-text-color {
	color: rgb(229, 255, 0);
}

.heading-highlight {
	background: rgba(0, 0, 0, 0.5);
}

/*
	=========
	Animations
	=========
*/

.left-slide-in {
	animation: left-slide-in 0.8s ease-in-out;
}

@keyframes left-slide-in {
  from {
    transform: translateX(-150px);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.right-slide-in {
	animation: right-slide-in 0.8s ease-in-out;
}

@keyframes right-slide-in {
  from {
    transform: translateX(150px);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.flash {
	animation: flash 2s ease infinite;
}



@keyframes flash {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

/*
	=========
	Responsiveness
	=========
*/

/* mobile display */
@media screen and (max-width: 767px) {
  .narrow {
		width: 80%;
	}

	.space-out {
		margin: 2em 0;
	}

	p, h3 {
		text-align: center;
	}
}