.footer {
  flex-direction: column;
  color: #fff;
  background: #000;
  padding: 1em;
}

.footer .social {
  display: flex;
  font-size: 1.4em;
}

.footer .social li {
  margin: 0 0.5em;
  transition: transform 0.25s;
}

.footer .social li:hover {
  transform: scale(1.4);
}

.footer .copyright {
  font-size: 0.8em;
  margin-top: 0.5em;
}