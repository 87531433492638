.skill {
  flex-direction: column;
  position: relative;
  padding: 0.5em 1.5em;
  border: 2px solid #fff;
  border-radius: 15px;
  background: #000;
}

.skill img {
  height: 50px;
}

.skill h3 {
  margin-top: 0.8em;
  font-size: 0.9em;
  font-weight: 600;
}

/* mobile display */
@media screen and (max-width: 767px) {
  .skill img {
    height: 70px;
  }

  .skill {
    width: 130px;
  }
}