.project {
  display: flex;
}

.project .preview {
  position: relative;
  flex-basis: 70%;
  transition: transform 0.2s;
  border: 2.5em solid rgb(42, 95, 58);
  box-sizing: content-box;
}

.project .preview::after {
  content: "";
  display: block;
  padding-top: 46.3333333333333%;
  box-sizing: content-box;
}

.projects li:first-child .preview,
.projects li:nth-child(3) .preview {
  transform-origin: left;
}

.projects li:nth-child(2) .preview,
.projects li:last-child .preview {
  transform-origin: right;
}

.project .preview:hover {
  transform: scale(1.05);
}

.project .media {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgb(42, 95, 58) center / contain no-repeat;
}

.media.odinbook {
  background-image: url('/src/assets/gifs/odinbook.gif');
}

.media.battleship {
  background-image: url('/src/assets/gifs/battleship.gif');
}

.media.shopping-cart {
  background-image: url('/src/assets/gifs/shopping-cart.gif');
}

.media.todo-list {
  background-image: url('/src/assets/gifs/todo.gif');
}

/* hide details layout reserved for mobile display */
.project .details.mobile {
  display: none;
}

.project .details {
  flex-basis: 30%;
  padding-top: 1.5em;
}

.project h3 {
  font-weight: 700;
  font-size: 2em;
  margin-bottom: 0.5em;
}

.project .description {
  margin-bottom: 3em;
  font-size: 1.1em;
}

.project .sub-description {
  margin-top: 1em;
  font-size: 0.7em;
  display: block;
}

.project .link {
  margin: 1em 0;
  letter-spacing: 2px;
  white-space: nowrap;
}

.project .link a {
  padding: 0.3em;
  position: relative;
}

.project .link a::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  transform: scaleY(0.05);
  transform-origin: bottom;
  transition: transform 0.25s;
  background: red;
}

.project .link a:hover::after {
  transform: scaleY(0.99);
}

/* mobile display */
@media screen and (max-width: 767px) {
  .project,
  .project .preview,
  .project .details.mobile {
    display: block;
  }

  .project .preview:hover {
    transform: none;
  }

  .project .details.regular {
    display: none;
  }

  .project .details.mobile {
    padding: 0 2em 2em 2em;
  }

  /* remove bottom margin from description so it doesn't add up to container
  padding added above */
  .project .description {
    margin: 0;
  }

  .project .links {
    display: flex;
    justify-content: space-evenly;
  }

  .project .link {
    padding: 0 0.5em;
  }
}