.about {
  text-align: center;
}

.person-icon {
  font-size: 200px;
}

.about .description {
  font-weight: 500;
  margin-top: 2em;
}

/* mobile display */
@media screen and (max-width: 767px) {
  .person-icon {
    font-size: 150px;
  }
}